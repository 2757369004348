<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <div class="mh-100vh bg-white content-page" v-else>
      <RegisterReviewForm
        :form="form"
        :v="$v"
        @checkFillBirthdate="validateEmail"
        :errorBirthDate="errorBirthDate"
        :validateBirthDate="validateBirthDate"
        title="ตรวจสอบข้อมูล"
        :isDisplayTelephone="true"
        :disableBtn="disableBtn"
        :customField="customField"
        :questionList="questionList"
        :chkBirthDay="chkBirthDay"
        :is_privacy_policy1="is_privacy_policy1"
      />
      <ModalError ref="modalError" :text="successMessage" />
    </div>
  </div>
</template>

<script>
import {
  required,
  helpers,
  email,
  minLength,
  requiredIf
} from "vuelidate/lib/validators";
import ModalError from "@/components/alert-modal/ModalError";
import moment from "moment";
import RegisterReviewForm from "@/components/register/RegisterReviewForm";
const alpha = helpers.regex("alpha", /^[ก-๙a-zA-Z]*$/);
import OtherLoading from "@/components/other/OtherLoading";
export default {
  components: {
    ModalError,
    RegisterReviewForm,
    OtherLoading
  },
  data() {
    return {
      chkBirthDay: process.env.VUE_APP_BIRTHDAY,
      form: {
        registerTypeId: 2,
        SocialProvider: "line",
        SocialId: "",
        first_name_th: "",
        last_name_th: "",
        email: "",
        telephone: "",
        birthday: "",
        firstname_en: "",
        lastname_en: "",
        display_name: "",
        password: "",
        branch_id: "0",
        home_address: "",
        alley: "",
        town: "",
        road: "",
        subdistrict: "",
        district: "",
        province: "",
        zip_code: "",
        gender: "",
        t_datetime: "",
        line_id: "",
        the_one_card_member: "",
        line_ref: "",
        accept_terms_and_conditions: 1,
        birthday_day: "",
        birthday_month: "",
        birthday_year: null,
        is_consent: 0
      },
      pairform: {
        registerTypeId: 2,
        socialProvider: "line",
        socialId: "",
        telephone: ""
      },
      acceptTerm: false,
      isLoading: true,
      required: false,
      validateBirthDate: false,
      errorBirthDate: "",
      successMessage: "",
      emailFirst: "",
      disableBtn: 0,
      customField: [],
      questionList: [],
      checkBD18: true,
      is_privacy_policy1: 0
    };
  },
  validations() {
    return {
      form: {
        first_name_th: { required, alpha },
        last_name_th: { required, alpha },
        email: { email },
        telephone: { required, minLength: minLength(9) },
        birthday_day: { required },
        birthday_month: { required },
        birthday_year: {
          required: requiredIf(function (item) {
            return this.chkBirthDay === "Diageo";
          }),
          minLength: minLength(4)
        }
      },
      questionList: {
        $each: {
          answer_list: {
            $each: {
              answer: {
                required: requiredIf(function (item) {
                  return !item.answer && item.required === 1;
                })
              }
            }
          }
        }
      }
    };
  },
  async created() {
    if (!this.$cookies.get("hourglass_register_success")) {
      await this.getUserProfile();
    } else {
      this.$router.push("/profile");
    }
  },
  methods: {
    async getUserProfile() {
      await this.$store.dispatch("getUserApi");
      this.form = this.$store.state.shortProfile;
      // this.form.birthday = moment(this.form.birthday).format("YYYY-MM-DD");
      this.form.birthday_year =
        this.form.have_year_birth === false ? "" : this.form.birthday_year;
      this.emailFirst = this.form.email;
      await this.getCustomField();
    },
    async getCustomField() {
      await this.$axios
        .get(
          `${process.env.VUE_APP_API}/api/v1/User/custom_field/${this.form.user_guid}`
        )
        .then(async data => {
          this.isLoading = false;
          if (data.result === 1) {
            this.customField = data.detail;
            for (const field of this.customField) {
              if (field.answer_list.length > 0) {
                if (field.field_type_id === 2) {
                  let body = {
                    question_id: field.question_id,
                    field_type_id: field.field_type_id,
                    answer_list: field.answer_list
                  };
                  body.answer_list[0].required = field.required;
                  this.questionList.push(body);
                } else {
                  for (const answer of field.answer_list) {
                    this.questionList.push({
                      question_id: field.question_id,
                      field_type_id: field.field_type_id,
                      answer_list: [
                        {
                          answer_id: answer.answer_id,
                          answer: answer.answer,
                          required: field.required
                        }
                      ]
                    });
                  }
                }
              } else {
                if (field.choice_list.length > 0) {
                  for (const choice of field.choice_list) {
                    if (choice.is_default) {
                      this.questionList.push({
                        question_id: field.question_id,
                        field_type_id: field.field_type_id,
                        answer_list: [
                          {
                            answer_id: choice.choice_id,
                            answer: choice.choice,
                            required: field.required
                          }
                        ]
                      });
                    }
                  }
                } else {
                  this.questionList.push({
                    question_id: field.question_id,
                    field_type_id: field.field_type_id,
                    answer_list: [
                      {
                        answer_id: 0,
                        answer: "",
                        required: field.required
                      }
                    ]
                  });
                }
              }
            }
          }
        });
    },
    async validateEmail(val) {
      this.form = val.form;
      this.checkBD18 = val.chk18Birthday;
      if (!this.form.birthday) {
        this.errorBirthDate = "error";
        this.validateBirthDate = true;
      } else {
        this.errorBirthDate = "";
        this.validateBirthDate = false;
      }
      this.pairform.telephone = this.form.telephone;
      this.pairform.socialId = this.$cookies.get("user_profile_token").userId;
      this.$v.questionList.$touch();
      if (this.$v.questionList.$error) {
        return;
      }
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      if (!this.checkBD18) {
        return;
      }
      this.isLoading = true;
      if (this.form.email != this.emailFirst) {
        this.$v.form.$touch();
        if (this.$v.form.$error) {
          return;
        }
        let chkEmail = {
          Email: this.form.email
        };
        this.disableBtn = true;
        await this.$axios
          .post(
            `${process.env.VUE_APP_API}/api/v1/user/ValidateEmail`,
            chkEmail
          )
          .then(data => {
            if (data.result == 1) {
              this.checkForm();
            } else {
              this.successMessage = "Email นี้เคยสมัครแล้ว";
              this.$refs.modalError.show();
              this.isLoading = false;
              this.disableBtn = false;
            }
          });
      } else {
        this.checkForm();
      }
    },
    checkForm: async function () {
      this.isLoading = true;
      await this.$axios
        .post(`${process.env.VUE_APP_API}/api/v1/user/pair`, this.pairform)
        .then(data => {
          if (data.result == 1) {
            this.$cookies.set("hourglass_token", data.detail.token);
            this.editProfile();
          } else {
            this.successMessage = data.message;
            this.$refs.modalError.show();
            this.isLoading = false;
            this.disableBtn = false;
          }
        });
    },

    async editProfile() {
      if (!this.form.gender) {
        this.form.gender = "N/A";
      }
      if (!this.form.birthday_year) {
        this.form.birthday_year = null;
      }
      if (this.form.is_consent) {
        this.form.is_consent = 1;
      } else {
        this.form.is_consent = 0;
      }
      await this.$axios
        .post(
          `${process.env.VUE_APP_API}/api/v1/user/EditUserProfile`,
          this.form
        )
        .then(data => {
          if (data.result == 1) {
            if (this.customField.length > 0) {
              this.updateCustomField();
            } else {
              this.isLoading = false;
              this.$cookies.set("popupModalRegister", true);
              this.$router.push("/profile");
            }
          } else {
            if (data.detail[0]) {
              this.successMessage = data.detail[0];
            } else {
              this.successMessage = data.message;
            }
            this.$refs.modalError.show();
            this.isLoading = false;
          }
        });
    },
    async updateCustomField() {
      let body = {
        user_guid: this.form.user_guid,
        admin_user_guid: this.form.user_guid,
        question_list: this.questionList
      };
      await this.$axios
        .put(
          `${process.env.VUE_APP_API}/api/v1/User/update_custom_answer`,
          body
        )
        .then(async data => {
          this.isLoading = false;
          if (data.result == 1) {
            this.$cookies.set("popupModalRegister", true);
            this.$router.push("/profile");
          } else {
            this.disableBtn = false;
            if (data.detail[0]) {
              this.successMessage = data.detail[0];
            } else {
              this.successMessage = data.message;
            }
            this.$refs.modalError.show();
          }
        });
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    dateFormat() {
      let today = new Date();
      return moment(today).format();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .date-filter .vdatetime-input {
  border-bottom: 1px solid #dbdbdb;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 10px;
  border-radius: 0%;
}
.label-remark {
  color: red;
}
.ft-20 {
  font-size: 20px;
}
.div-space {
  margin: 90px 0px;
}
.text-error {
  color: #ff0000;
  font-size: 14px;
}
.vdatetime-input.error {
  border-color: red !important;
}
.cl-gray {
  color: #575757;
}
.content-page {
  margin-top: 80px;
  overflow: scroll;
  min-height: 100vh;
}
</style>
