<template>
  <div>
    <div class="text-center f-size-20 p-3 bg-gray-2">
      <p class="font-weight-bold m-0 ft-20">{{ title }}</p>
    </div>
    <div class="p-3">
      <b-row>
        <b-col>
          <InputText
            v-model="form.first_name_th"
            textFloat="ชื่อ"
            placeholder="ชื่อ"
            type="text"
            name="first_name_th"
            size="lg"
            isRequired
            :isValidate="v.form.first_name_th.$error"
            :v="v.form.first_name_th"
            class="f-regular"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <InputText
            v-model="form.last_name_th"
            textFloat="นามสกุล"
            placeholder="นามสกุล"
            type="text"
            name="last_name_th"
            size="lg"
            isRequired
            :isValidate="v.form.last_name_th.$error"
            :v="v.form.last_name_th"
            class="f-regular"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <InputText
            v-model="form.email"
            textFloat="อีเมล"
            placeholder="อีเมล"
            type="text"
            name="email"
            size="lg"
            class="f-regular"
            :isValidate="v.form.email.$error"
            :v="v.form.email"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <InputText
            v-model="form.telephone"
            textFloat="เบอร์โทรศัพท์"
            placeholder="เบอร์โทรศัพท์"
            type="text"
            name="telephone"
            size="lg"
            maxlength="10"
            :isDisplay="isDisplayTelephone"
            isRequired
            @onkeypress="isNumber($event)"
            :isValidate="v.form.telephone.$error"
            :v="v.form.telephone"
            class="f-regular"
          />
        </b-col>
      </b-row>
      <!-- <b-row>
                <b-col>
                <label class="label-text">วัน/เดือน/ปีเกิด <span class="label-remark">*</span></label>
                <datetime
                    type="date"
                    placeholder="วัน/เดือน/ปีเกิด"
                    :class="['date-picker-input date-filter' , errorBirthDate]"
                    format="dd/MM/yyyy"
                    value-zone="Asia/Bangkok"
                    :max-datetime="dateFormat()"
                    v-model="form.birthday"
                ></datetime>
                </b-col>
            </b-row> -->
      <div>
        <InputSelect
          title="วันเกิด"
          text="วันเกิด"
          name="day"
          placeholder="วันเกิด"
          valueField="value"
          textField="text"
          v-bind:options="day"
          v-model="form.birthday_day"
          :value="form.birthday_day"
          @onDataChange="val => (form.birthday_day = val)"
          isRequired
          :isValidate="v.form.birthday_day.$error"
          :v="v.form.birthday_day"
          class="f-regular"
          ref="day"
        />
      </div>
      <div>
        <InputSelect
          title="เดือนเกิด"
          text="เดือนเกิด"
          name="birthday_month"
          placeholder="เดือนเกิด"
          valueField="value"
          textField="text"
          v-bind:options="month"
          v-model="form.birthday_month"
          :value="form.birthday_month"
          @onDataChange="val => (form.birthday_month = val)"
          isRequired
          :isValidate="v.form.birthday_month.$error"
          :v="v.form.birthday_month"
          class="f-regular"
          ref="birthday_month"
        />
      </div>
      <div v-if="chkBirthDay1 !== 'Diageo'">
        <InputText
          v-model="form.birthday_year"
          textFloat="ปีเกิด"
          textRemark="(กรุณากรอกปี ค.ศ.)"
          placeholder="ปีเกิด"
          type="number"
          name="birthday_year"
          class="f-regular"
          :isValidate="v.form.birthday_year.$error"
          @onDataChange="birthDayYear"
          :v="v.form.birthday_year"
          oninput="if( this.value.length > 4 )  this.value = this.value.slice(0,4)"
        />
      </div>
      <div v-else>
        <InputText
          v-model="form.birthday_year"
          textFloat="ปีเกิด"
          textRemark="(กรุณากรอกปี ค.ศ.)"
          placeholder="ปีเกิด"
          type="number"
          name="birthday_year"
          class="f-regular"
          :isValidate="v.form.birthday_year.$error"
          :v="v.form.birthday_year"
          @onDataChange="birthDayYear"
          oninput="if( this.value.length > 4 )  this.value = this.value.slice(0,4)"
        />
      </div>
      <div v-if="!birthDay18" class="text-error">
        ผู้สมัครต้องมีอายุมากกว่า 18 ปี
      </div>
      <div v-if="validateBirthDate">
        <span class="text-error"> กรุณากรอกข้อมูลให้ครบถ้วน </span>
      </div>
      <div v-if="customField.length > 0">
        <CustomFieldPanel
          :customField="customField"
          :v="v"
          :questionList="questionList"
        />
      </div>
      <b-row class="mt-4">
        <b-col>
          <b-form-checkbox
            v-model="form.is_consent"
            :value="1"
            :unchecked-value="0"
            name="checkbox-1"
            ref="input"
            class="consent-text"
          >
            ฉันต้องการรับสิทธิพิเศษและโปรโมชั่น รวมถึงข่าวสารจาก Hourglass
            Thailand ตามที่ระบุไว้ใน
            <span class="text-underline" @click.prevent="modalPolicyOpen"
              >นโยบายคุ้มครองข้อมูลส่วนบุคคล</span
            >
          </b-form-checkbox>
        </b-col>
      </b-row>
      <!-- <b-row class="mt-4">
        <b-col>
          <b-form-checkbox
            v-model="form.is_privacy_policy"
            :value="1"
            :unchecked-value="0"
            name="checkbox-2"
            class="consent-text"
          >
            ฉันได้อ่านและตกลงตาม<span
              class="text-underline"
              @click.prevent="modalPolicyOpen"
              >นโยบายคุ้มครองข้อมูลส่วนบุคคล"</span
            >
          </b-form-checkbox>
        </b-col>
      </b-row> -->
      <b-row class="mt-4">
        <b-col>
          <b-form-checkbox
            v-model="is_privacy_policy_2"
            :value="1"
            :unchecked-value="0"
            name="checkbox-2"
            class="consent-text"
          >
            ฉันขอให้ความยินยอมโดยชัดแจ้งในการเก็บรวบรวม ใช้
            และเปิดเผยข้อมูลส่วนบุคคลของฉัน ดังมีรายละเอียดปรากฏตาม<span
              class="text-underline"
              @click.prevent="modalPolicyOpen"
              >นโยบายคุ้มครองข้อมูลส่วนบุคคล</span
            >
          </b-form-checkbox>
        </b-col>
      </b-row>
    </div>
    <div class="div-space"></div>
    <div class="register-bottom fixed-bottom bg-white p-2 shadow">
      <b-button
        class="w-100"
        :style="`background-color: ${$store.state.theme.themePrimaryColor};`"
        @click="checkFillBirthdate"
        :disabled="is_privacy_policy_2 === 0 ? true : false"
        >สมัครสมาชิก</b-button
      >
    </div>
    <ModalPolicy ref="modalPolicy" />
    <ModalTermAndConditios ref="modalTerms" />
  </div>
</template>

<script>
import InputText from "@/components/input/InputText";
import InputSelect from "@/components/input/InputSelect";
import moment from "moment";
import CustomFieldPanel from "@/components/custom-fields/CustomFieldPanel";
import ModalPolicy from "@/components/alert-modal/ModalPolicy";
import ModalTermAndConditios from "@/components/alert-modal/ModalTermAndConditios";
export default {
  props: {
    form: {
      required: true,
      type: Object
    },
    v: {
      required: true,
      type: Object
    },
    errorBirthDate: {
      required: false,
      type: String
    },
    validateBirthDate: {
      required: true,
      type: Boolean
    },
    isLoading: {
      required: false,
      type: Boolean
    },
    title: {
      required: false,
      type: String
    },
    isDisplayTelephone: {
      required: false,
      type: Boolean
    },
    disableBtn: {
      required: false,
      type: Number
    },
    customField: {
      required: true,
      type: Array
    },
    questionList: {
      required: true,
      type: Array
    },
    chkBirthDay: {
      required: true,
      type: String
    },
    is_privacy_policy1: {
      required: true,
      type: Number
    }
  },
  components: {
    InputText,
    InputSelect,
    CustomFieldPanel,
    ModalPolicy,
    ModalTermAndConditios
  },
  data() {
    return {
      is_privacy_policy_2: this.is_privacy_policy1,
      acceptTerm: false,
      required: false,
      disableBtn1: 0,
      chkBirthDay1: this.chkBirthDay,
      birthDay18: true,
      day: [
        { value: "", text: "วันเกิด", disabled: true },
        { value: "01", text: "01" },
        { value: "02", text: "02" },
        { value: "03", text: "03" },
        { value: "04", text: "04" },
        { value: "05", text: "05" },
        { value: "06", text: "06" },
        { value: "07", text: "07" },
        { value: "08", text: "08" },
        { value: "09", text: "09" },
        { value: "10", text: "10" },
        { value: "11", text: "11" },
        { value: "12", text: "12" },
        { value: "13", text: "13" },
        { value: "14", text: "14" },
        { value: "15", text: "15" },
        { value: "16", text: "16" },
        { value: "17", text: "17" },
        { value: "18", text: "18" },
        { value: "19", text: "19" },
        { value: "20", text: "20" },
        { value: "21", text: "21" },
        { value: "22", text: "22" },
        { value: "23", text: "23" },
        { value: "24", text: "24" },
        { value: "25", text: "25" },
        { value: "26", text: "26" },
        { value: "27", text: "27" },
        { value: "28", text: "28" },
        { value: "29", text: "29" },
        { value: "30", text: "30" },
        { value: "31", text: "31" }
      ],
      month: [
        { value: "", text: "เดือน", disabled: true },
        { value: "01", text: "มกราคม" },
        { value: "02", text: "กุมภาพันธ์" },
        { value: "03", text: "มีนาคม" },
        { value: "04", text: "เมษายน" },
        { value: "05", text: "พฤษภาคม" },
        { value: "06", text: "มิถุนายน" },
        { value: "07", text: "กรกฎาคม" },
        { value: "08", text: "สิงหาคม" },
        { value: "09", text: "กันยายน" },
        { value: "10", text: "ตุลาคม" },
        { value: "11", text: "พฤศจิกายน" },
        { value: "12", text: "ธันวาคม" }
      ]
    };
  },
  methods: {
    birthDayYear(val) {
      if (this.chkBirthDay1 === "Diageo") {
        const date18YrsAgo = new Date();
        var date = new Date(
          `${this.form.birthday_day}/${this.form.birthday_month}/${val}`
        );
        // date.toString();
        date18YrsAgo.setFullYear(date18YrsAgo.getFullYear() - 18);
        this.birthDay18 = date <= date18YrsAgo;
        // return date <= date18YrsAgo;
      }
    },
    dateFormat() {
      let today = new Date();
      return moment(today).format();
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    checkFillBirthdate() {
      // this.$emit("checkFillBirthdate", this.form);
      this.$emit("checkFillBirthdate", {
        form: this.form,
        chk18Birthday: this.birthDay18
      });
    },
    modalPolicyOpen() {
      this.$router.push("/policy");
      // this.$refs.modalPolicy.show();
    },
    modalTermsOpen() {
      this.$router.push("/terms");
      this.$refs.modalTerms.show();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .date-filter .vdatetime-input {
  border-bottom: 1px solid #dbdbdb;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 10px;
  border-radius: 0%;
}
.label-remark {
  color: red;
}
.ft-20 {
  font-size: 20px;
}
.div-space {
  margin: 90px 0px;
}
.text-error {
  color: #ff0000;
  font-size: 14px;
}
::v-deep .vdatetime.date-picker-input.date-filter.error {
  border-bottom: 1px solid red !important;
}
.consent-text {
  font-size: 14px;
  color: #575757;
}
.text-underline {
  text-decoration: underline;
}
</style>